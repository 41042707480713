import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'redux-saga-first-router';
import { withTranslation } from 'react-i18next';
import { FREE_TRIAL_SELECT, FREE_TRIAL_SUBMIT_DATA } from './constants';

const Tools = {
  'free-trial': {
    'V-Ray': ['3ds Max', 'Maya', 'SketchUp', 'Rhino', 'Revit', 'Cinema 4D', 'Unreal', 'Houdini', 'Nuke'],
    Phoenix: ['3ds Max', 'Maya'],
  },
  'enscape-trial': {
    Windows: ['Revit', 'SketchUp', 'Rhino', 'ArchiCAD', 'Vectorworks'],
    MacOs: ['SketchUp', 'Rhino', 'ArchiCAD', 'Vectorworks'],
  },
};

const PRODUCT_SPECIFIC_PROPS = {
  'free-trial': {
    toolKey: 'product_of_interest',
    platformKey: 'platform_of_interest',
    toolTitle: 'What is your primary tool of choice?',
    platformTitle: 'Host platform',
  },
  'enscape-trial': {
    toolKey: 'os',
    platformKey: 'enscape_platform_of_interest',
    toolTitle: 'What operating system will you use?',
    platformTitle: 'What is your primary tool of choice?',
  },
};

const Tool = ({
  routing,
  product,
  freeTrial,
  onSelectTool,
  onSelectPlatform,
  setIsEnscapeTrial,
  setIsImpactUser,
  onContinue,
  t,
}) => {
  const isEnscape = window.location.hostname === window.domains.enscape;
  const { toolKey, platformKey, toolTitle, platformTitle } = PRODUCT_SPECIFIC_PROPS[product];

  const selectedTool = freeTrial.selected[toolKey];
  const selectedPlatform = freeTrial.selected[platformKey];

  useEffect(() => {
    if (isEnscape) {
      onSelectTool('enscape_product_of_interest', 'Enscape');
      setIsEnscapeTrial();
      // Only set is_impact_user to true if it's not already set by the user
      if (freeTrial.selected.is_impact_user === undefined) {
        setIsImpactUser(true);
      }
    }
  }, []);

  const productTools = Tools[product];

  return (
    <div className="content tool">
      <p>{t(toolTitle)}</p>
      <div className="roles" style={{ width: 460 }}>
        {Object.keys(productTools).map((tool) => (
          <div
            key={tool}
            onClick={() => {
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: 'UserAction',
                  formName: 'TrialForm',
                  formType: 'TrialForm',
                  formStep: 'Step1',
                  [toolKey]: tool,
                });
              }
              onSelectTool(toolKey, tool);
              if (tool === 'MacOs') {
                setIsImpactUser(false);
              }
              if (tool === 'Windows') {
                setIsImpactUser(true);
              }
            }}
            className={selectedTool === tool ? 'selected' : null}
          >
            <img src={`/images/trial/${tool.toLowerCase()}.svg`} alt="" />
            <br />
            <span>{tool}</span>
          </div>
        ))}
      </div>

      <br />
      <br />
      {selectedTool && (
        <>
          <p>{t(platformTitle)}</p>
          <div className="tools">
            {productTools[selectedTool].map((platform) => (
              <div
                key={platform}
                onClick={() => onSelectPlatform(platformKey, platform)}
                className={selectedPlatform === platform ? 'selected' : null}
              >
                <img src={`/images/trial/platforms/${platform}.svg`} alt="" />
                <br />
                <span>{platform}</span>
              </div>
            ))}
          </div>
          <br />
          <br />
        </>
      )}
      {freeTrial.selected.os === 'Windows' && product === 'enscape-trial' && (
        <div className="enscape-impact">
          <div>{t('Are you interested in testing Enscape Impact Beta?')}</div>
          <div className="info">
            {t('You will get exclusive updates and training materials')}{' '}
            <div className="tooltip">
              <img src="/images/trial/enscape-impact-info.svg" alt="info" />
              <span className="tooltiptext">
                <div className="tooltiptitle">Enscape Impact</div>
                <div className="text">
                  {t(
                    'Add-on for Enscape to analyze the energy efficiency and environmental impact of buildings. Visualize which rooms have the highest energy consumption, need the most heating or cooling energy and where solar gains work best.',
                  )}
                </div>
                <a target="_blank" rel="noreferrer" href={`${window.ENSCAPE_URL}/impact`}>
                  {t('Learn more')}
                </a>
              </span>
            </div>
          </div>
          <div className="buttons">
            <button
              type="button"
              className={`button ${freeTrial.selected.is_impact_user ? 'selected' : ''}`}
              onClick={() => {
                setIsImpactUser(true);
              }}
            >
              {t('Yes')}
            </button>
            <button
              type="button"
              className={`button ${freeTrial.selected.is_impact_user ? '' : 'selected'}`}
              onClick={() => setIsImpactUser(false)}
            >
              {t('No')}
            </button>
          </div>
        </div>
      )}
      <button
        type="button"
        className="button"
        onClick={() => {
          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'UserAction',
              formName: 'TrialForm',
              formType: 'TrialForm',
              formStep: 'Step2',
              platform_of_interest: selectedPlatform,
            });
          }
          onContinue(routing.params);
        }}
        disabled={!selectedPlatform || freeTrial.loading}
      >
        {t('Continue')}
      </button>
    </div>
  );
};

export default connect(
  (state) => ({
    freeTrial: state.freeTrial,
    routing: state.routing,
  }),
  (dispatch) => ({
    onSelectTool: (key, tool) => dispatch({ type: FREE_TRIAL_SELECT, data: { [key]: tool } }),
    onSelectPlatform: (key, platform) => dispatch({ type: FREE_TRIAL_SELECT, data: { [key]: platform } }),
    setIsEnscapeTrial: () => dispatch({ type: FREE_TRIAL_SELECT, data: { isEnscapeTrial: true } }),
    setIsImpactUser: (isImpactUser) => dispatch({ type: FREE_TRIAL_SELECT, data: { is_impact_user: isImpactUser } }),
    onContinue: (routingParams) => {
      dispatch({ type: FREE_TRIAL_SUBMIT_DATA });
      dispatch(navigate('FREE_TRIAL', { ...routingParams, step: 'role' }));
    },
  }),
)(withTranslation()(Tool));
